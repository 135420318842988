<template>
  <div @click.stop>
    <nav class="top-bar topbar-fixed bg-primary-900 flex flex-col z-30">
      <div class="nav-container w-page-2xl topbar-layout">
        <!-- Title logo element -->
        <button class="topbar-logo" @click="goto('Home')">
          <img src="../../assets/images/logos/Lodylearn_Logo_white.svg" alt="Lodylearn" class="brand-logo"/>
        </button>
        <!-- end of title element -->
        <!-- topbar menu -->
        <div class="top-bar-menu">
          <router-link :to="{name: $routeNames.Search}" class="menu-link">Modules</router-link>
        </div>
        <!-- end of menu -->
        <!-- middle btn element -->
        <div class="topbar-toggle">
          <svg xmlns="http://www.w3.org/2000/svg" width="44" height="26" viewBox="0 0 29 18" v-if="!open" @click="toggleMenu">
            <g id="Menu" transform="translate(0.227 0.488)">
              <rect id="Rectangle_7" data-name="Rectangle 7" width="21" height="4" rx="2" transform="translate(-0.227 13.512)" fill="#fff"/>
              <rect id="Rectangle_8" data-name="Rectangle 8" width="29" height="4" rx="2" transform="translate(-0.227 6.512)" fill="#fff"/>
              <rect id="Rectangle_9" data-name="Rectangle 9" width="29" height="4" rx="2" transform="translate(-0.227 -0.488)" fill="#fff"/>
            </g>
          </svg>
          <svg id="Menu" xmlns="http://www.w3.org/2000/svg" width="35.355"
               class="flex flex-shrink-0 m-auto cursor-pointer" height="35.355" viewBox="0 0 35.355 35.355" v-if="open"
               @click="toggleMenu">
            <rect id="Rectangle_8" data-name="Rectangle 8" width="44" height="6" rx="3"
                  transform="translate(4.243 0) rotate(45)" fill="#fff"/>
            <rect id="Rectangle_9" data-name="Rectangle 9" width="44" height="6" rx="3"
                  transform="translate(0 31.113) rotate(-45)" fill="#fff"/>
          </svg>
        </div>
        <!-- end of middle btn element -->

        <!-- right nav elements (connection / register / shoppingCart) -->
        <div class="topbar-account" @mouseleave="closeCartDropdown">
          <top-bar-search></top-bar-search>
          <router-link :to="{name: $routeNames.LogIn}" v-if="!connected"
                       class="btn n-mobile my-auto border-2 border-white px-8 py-2 bg-transparent hover:bg-white hover:text-black text-white rounded-full mr-3 transition">
            <span>Connexion</span>
          </router-link>
          <router-link :to="{name: $routeNames.SignIn}" v-if="!connected"
                       class="btn n-mobile border-2 my-auto border-primary-500 py-2 px-8 bg-primary-500 hover:bg-blue-500 hover:border-blue-500 text-white rounded-full mr-3 transition">
            <span>Inscription</span>
          </router-link>
          <button v-if="connected" @click="toggleAccount"
                       class="account-picture-container">
            <div class="account-picture">
              <span>{{ accountName[0] }}</span>
            </div>
          </button>
          <button
              @mouseenter="openCartDropdown"
              @click="gotoCart"
              class="shopping-cart-container">
              <span v-if="shoppingCartCount > 0"
                    class="shopping-cart-badge">
                <span class="m-auto font-bold" v-if="shoppingCartCount < 10">{{ shoppingCartCount }}</span>
                <span class="m-auto font-bold" v-if="shoppingCartCount >= 10">+</span>
              </span>
            <svg class="fill-current" xmlns="http://www.w3.org/2000/svg" id="Calque_1"
                 data-name="Calque 1" viewBox="0 0 200 200">
              <path class="cls-1"
                    d="M183.46208,42.14a7.46878,7.46878,0,0,0-5.71-2.64L58.18206,39.49,55.65215,23.81a7.49015,7.49015,0,0,0-7.4-6.31h-26a7.5,7.5,0,0,0,0,15h19.61l2.4,15.85c.01.23.03.45.06006.68l13.02,92.12a7.49562,7.49562,0,0,0,7.40991,6.35h96.75a7.5,7.5,0,0,0,0-15H71.18206l-1.04993-6.75,98.31994-9.28a7.48691,7.48691,0,0,0,6.70008-6.28l10-62A7.49594,7.49594,0,0,0,183.46208,42.14Zm-22.18994,59.94-93.08007,8.78-7.5-53.98-.33-2.39h.24l108.34.01Z"/>
              <circle class="cls-1" cx="87.50212" cy="167" r="15.5"/>
              <circle class="cls-1" cx="138.50212" cy="167" r="15.5"/>
            </svg>
          </button>
          <account-popunder :account-open="accountOpen"></account-popunder>
          <shopping-cart-popunder :cart-open="cartOpen" :total="total"></shopping-cart-popunder>
        </div>
        <!-- end of right nav elements -->
      </div>
    </nav>
    <div class="navigation-spacer"></div>
    <div
        class="topbar-slide"
        :class="{'open': open}">
      <div class="site-links">
        <router-link v-for="route in navigations" :key="route.label" :to="route.link"
                     class="font-title text-2xl">
          {{ route.label }}
        </router-link>
        <router-link :to="{name: $routeNames.ShoppingCart}" class="font-title text-2xl mobile">
          Mon Panier
        </router-link>
        <div class="flex mt-8 mobile">
          <router-link :to="{name: $routeNames.LogIn}" v-if="!connected"
                       class="ml-8 my-auto border-2 border-white px-8 py-2 bg-transparent hover:bg-white hover:text-black text-white rounded-full mr-3 transition">
            <span>Connexion</span>
          </router-link>
          <router-link :to="{name: $routeNames.SignIn}" v-if="!connected"
                       class="border-2 my-auto border-primary-500 py-2 px-8 bg-primary-500 hover:bg-blue-500 hover:border-blue-500 text-white rounded-full mr-3 transition">
            <span>Inscription</span>
          </router-link>
        </div>
      </div>
      <div class="mobile-links">
        <strong>© Lodylearn {{ (new Date()).getUTCFullYear() }}</strong><br>
        <button @click="openCgv" class="cursor-pointer transition underline hover:opacity-60">Conditions Générales de Ventes</button><br>
        <button @click="openLegals" class="cursor-pointer transition underline hover:opacity-60"> Mentions légales</button><br>
        <button @click="openPrivacy" class="cursor-pointer transition underline hover:opacity-60"> Politique de confidentialité</button>
      </div>
    </div>
    <cart-modal></cart-modal>
  </div>
</template>

<script>

import Component from 'vue-class-component';
import Vue from 'vue';
import { mapState } from 'vuex';

@Component({
  name: 'top-bar-navigation',
  components: {
    TopBarSearch: () => import(/* webpackPrefetch: true */ './top-bar-search.vue'),
    AccountPopunder: () => import(/* webpackPrefetch: true */ './account-popunder.vue'),
    ShoppingCartPopunder: () => import(/* webpackPrefetch: true */ './shopping-cart-popunder.vue'),
    CartModal: () => import(/* webpackPrefetch: true */ '@/views/elements/cart-modal.vue'),
  },
  data() {
    return {
      cartOpen: false,
      accountOpen: false,
    };
  },
  methods: {
    openCartDropdown() {
      this.cartOpen = true;
    },
    closeCartDropdown() {
      this.cartOpen = false;
    },
    gotoCart() {
      return this.$router.push({ name: this.$routeNames.ShoppingCart });
    },
    openCgv() {
      this.$store.commit('globalModal/cgv/SET_OPEN', true);
    },
    openLegals() {
      this.$store.commit('globalModal/legals/SET_OPEN', true);
    },
    openPrivacy() {
      this.$store.commit('globalModal/privacy/SET_OPEN', true);
    },
    toggleAccount() {
      this.accountOpen = !this.accountOpen;
      if (this.accountOpen) this.cartOpen = false;
    },
    goto(routeName) {
      this.$router.push({ name: routeName });
    },
    toggleMenu() {
      this.$store.commit('topBar/SET_OPEN', !this.open);
    },
    handleDocumentClick() {
      if (this.open) this.$store.commit('topBar/SET_OPEN', false);
      if (this.cartOpen) this.cartOpen = false;
      if (this.accountOpen) this.accountOpen = false;
    },
  },
  watch: {
    $route() {
      this.cartOpen = false;
      this.accountOpen = false;
    },
  },
  mounted() {
    this.$store.dispatch('shoppingCart/fetch');
  },
  computed: {
    ...mapState({
      open: (s) => s.topBar.open,
      navigations: (s) => s.topBar.navigations,
      cartItems: (s) => s.shoppingCart.fetchedModules,
      total: (s) => s.shoppingCart.fetchedModules.reduce((total, module) => module.price + total, 0),
    }),
    shoppingCartCount() {
      return this.$store.getters['shoppingCart/articleCount'];
    },
    connected() {
      return this.$store.getters['auth/isAuth'];
    },
    accountName() {
      return this.$store.getters['auth/account/name'];
    },
  },
})
export default class TopBarNavigation extends Vue {
}
</script>

<style scoped>
.cart-popunder, .account-popunder {
  transform-origin: top center;
  animation: growDown .2s linear forwards;
}

.before-gradient {
  position: relative;
}
.before-gradient::before {
  content: '';
  position: absolute;
  top: -40px;
  height: 40px;
  left: 0;
  right: 0;
  background: linear-gradient(to top, rgb(255,255,255), rgba(255,255,255,0));
}

/* Example */
.cart-list .cart-scrollable::-webkit-scrollbar {
  width: 2px;
  border-radius: 2px;
}
.cart-list .cart-scrollable::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}
.cart-list .cart-scrollable::-webkit-scrollbar-thumb {
  background-color: #2A4E96;
  outline: 1px solid #1E314C;
}

@keyframes growDown {
  0% {
    transform: scaleY(0)
  }
  80% {
    transform: scaleY(1.1)
  }
  100% {
    transform: scaleY(1)
  }
}
</style>
